(function($){
	// Smooth Scroll to div on anchors click...
	$('a[href*="#"]').not('a.noscroll').on('click', function (event) {
		event.preventDefault();
		let offset = 0;
		const target = this.hash;
		if($(this).data('offset') != undefined) offset = $(this).data('offset'); // if set data-offset="pixels"
		if($(target).length) {
			$('html, body').stop().animate({
				'scrollTop': $(target).offset().top - offset
			}, 900, 'swing', function() {
				window.location.hash = target;
			});
		}
		else { // Scrolls to top...
			$('html, body').stop().animate({
				'scrollTop': 0
			}, 900, 'swing', function() {
				window.location.hash = target;
			});
		}
	});
})(jQuery);