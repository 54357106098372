import Navigo from 'navigo';
// import * as feather from 'feather-icons'; // if not imported with webpack.ProvidePlugin
// const feather = require('feather-icons');

// getElementById wrapper
function getId(id) {
    return document.getElementById(id);
}
// asyncrhonously fetch the html template partial from the file directory, then set its contents to the html of the parent element
function loadHTML(url, id) {
    // alert(url+' / '+id);
    let req = new XMLHttpRequest();
    req.open('GET', url);
    req.send();
    req.onload = () => {
        getId(id).innerHTML = req.responseText;
    };
}
function setPageAfterload (link) {
    console.log(link);
    // Remove active state to all sidebar nav links then...
    $(".nav a.nav-link").not('[data-toggle="modal"]').each(function() {
        $(this).removeClass("active");
    });
    // Add active state to sidebar nav links
    const targetAnchors = document.body.querySelectorAll('[href="/' + link + '"].nav-link');
    targetAnchors.forEach(targetAnchor => {
        let parentNode = targetAnchor.parentNode;
        while (parentNode !== null && parentNode !== document.documentElement) {
            if (parentNode.classList.contains('collapse')) {
                parentNode.classList.add('show');
                const parentNavLink = document.body.querySelector(
                    '[data-bs-target="#' + parentNode.id + '"]'
                );
                parentNavLink.classList.remove('collapsed');
                parentNavLink.classList.add('active');
            }
            parentNode = parentNode.parentNode;
        }
        targetAnchor.classList.add('active');
    });
    /* // Old one
    $("#layoutSidenav_nav .sidenav a.nav-link").not('[data-toggle="modal"]').each(function() {
        $(this).removeClass("active");
        if (this.href.search(link) !== -1) {
            $(this).addClass("active");
            $(this).closest('.collapse').addClass('show').prev('.nav-link').removeClass('collapsed').addClass('active');
        }
    });
    */
    if(link=='index.html') $('.nav-link-def-route').addClass('active');
    // Now doing things like binding UI Actcions for this page...
    App.refreshInPages(link);
    $.sessionStorage.setItem('myPage', link); // Change current page to session
}
// Now the router... Depending on login
if($.localStorage.getItem('pass')=='OK' && document.URL.indexOf( 'login.html' ) === -1) {
    if(document.location.href.indexOf('resosign.fr/bo')!==-1) window.location.replace('https://bo.resosign.fr'); // Force online version to use web root !
    const rootUrl = '/';
    // const myDomain = window.location.origin; // https://my.domain
    // const rootUrl = (myDomain.indexOf('localhost')!==-1 || myDomain.indexOf('bo.resosign.fr')!==-1) ? '/' : '/bo'; // if online version is in a subfolder
    const router = new Navigo(rootUrl); // params are: root, useHash (Defaults to: false) and hash (Defaults to: '#')
    // use #! to hash
    // const router = new Navigo(null, true, '#!'); // params are: root, useHash (Defaults to: false) and hash (Defaults to: '#')
    router.hooks({
        // Ensure new data-navigo links work.
        after: function() {
            router.updatePageLinks();
        },
    });
    const routerContainer = document.getElementById('routerContainer');
    const routerContainerExist = (routerContainer) ? true : false;
    if(!routerContainerExist) document.location.href = './';
    // Direct To Some Page...
    // const goTo = App.urlParam('goto', document.URL); // Where are we going
    // if(goTo == 'page') router.navigate("/page");

    router.on({
        // 'routerContainer' is the id of the div element inside which we render the HTML
        '/': () => {
            fetch('/dashboard.html').then(function (response) {
                return response.text();
            }).then(function (html) {
                routerContainer.innerHTML = html;
                setPageAfterload('index.html');
                App.setDashPage();
                router.updatePageLinks(); // In case there are data-navigo links on the loaded page
            }).catch(function (err) {
                console.warn('Something went wrong.', err);
            });
        },
        '/campagnes': () => {
            fetch('/campaigns.html').then(function (response) {
                return response.text();
            }).then(function (html) {
                routerContainer.innerHTML = html;
                App.setCampaignsListPage();
                setPageAfterload('campagnes');
                router.updatePageLinks(); // In case there are data-navigo links on the loaded page
            }).catch(function (err) {
                console.warn('Something went wrong.', err);
            });
        },
        '/clients': () => {
            fetch('/clients.html').then(function (response) {
                return response.text();
            }).then(function (html) {
                routerContainer.innerHTML = html;
                App.setClientsListPage();
                setPageAfterload('clients');
                router.updatePageLinks(); // In case there are data-navigo links on the loaded page
            }).catch(function (err) {
                console.warn('Something went wrong.', err);
            });
        },
        '/clients/:id': ({data}) => {
            fetch('/client.html').then(function (response) {
                return response.text();
            }).then(function (html) {
                routerContainer.innerHTML = html;
                App.setClientPage(data.id);
                setPageAfterload('clients');
                router.updatePageLinks(); // In case there are data-navigo links on the loaded page
            }).catch(function (err) {
                console.warn('Something went wrong.', err);
            });
        },
        '/contacts': () => {
            fetch('/contacts.html').then(function (response) {
                return response.text();
            }).then(function (html) {
                routerContainer.innerHTML = html;
                App.setContactsListPage();
                setPageAfterload('contacts');
                router.updatePageLinks(); // In case there are data-navigo links on the loaded page
            }).catch(function (err) {
                console.warn('Something went wrong.', err);
            });
        },
        '/administratif': () => {
            fetch('/administratives.html').then(function (response) {
                return response.text();
            }).then(function (html) {
                routerContainer.innerHTML = html;
                App.setAdministrativeListPage();
                setPageAfterload('administratif');
                router.updatePageLinks(); // In case there are data-navigo links on the loaded page
            }).catch(function (err) {
                console.warn('Something went wrong.', err);
            });
        },
        '/poseurs': () => {
            fetch('/workers.html').then(function (response) {
                return response.text();
            }).then(function (html) {
                routerContainer.innerHTML = html;
                App.setWorkersListPage();
                setPageAfterload('poseurs');
                router.updatePageLinks(); // In case there are data-navigo links on the loaded page
            }).catch(function (err) {
                console.warn('Something went wrong.', err);
            });
        },
        '/poseurs/:id': ({data}) => {
            fetch('/worker.html').then(function (response) {
                return response.text();
            }).then(function (html) {
                routerContainer.innerHTML = html;
                App.setWorkerPage(data.id);
                setPageAfterload('poseurs');
                router.updatePageLinks(); // In case there are data-navigo links on the loaded page
            }).catch(function (err) {
                console.warn('Something went wrong.', err);
            });
        },
        '/carte': () => {
            fetch('/map.html').then(function (response) {
                return response.text();
            }).then(function (html) {
                routerContainer.innerHTML = html;
                App.setMapPage();
                setPageAfterload('carte');
                router.updatePageLinks(); // In case there are data-navigo links on the loaded page
            }).catch(function (err) {
                console.warn('Something went wrong.', err);
            });
        },
        '/agenda': () => {
            fetch('/calendar.html').then(function (response) {
                return response.text();
            }).then(function (html) {
                routerContainer.innerHTML = html;
                App.setCalendarPage();
                setPageAfterload('agenda');
                router.updatePageLinks(); // In case there are data-navigo links on the loaded page
            }).catch(function (err) {
                console.warn('Something went wrong.', err);
            });
        },
        '/commercial': () => {
            fetch('/commercial.html').then(function (response) {
                return response.text();
            }).then(function (html) {
                routerContainer.innerHTML = html;
                App.setCommercialPage();
                setPageAfterload('commercial');
                router.updatePageLinks(); // In case there are data-navigo links on the loaded page
            }).catch(function (err) {
                console.warn('Something went wrong.', err);
            });
        },
        '/dc': () => {
            App.logMeOut();
            // loadHTML('/logout.html', 'routerContainer');
        },
    });

    router.on(() => {
        fetch('/dashboard.html').then(function (response) {
            return response.text();
        }).then(function (html) {
            routerContainer.innerHTML = html;
            setPageAfterload('index.html');
            App.setDashPage();
            router.updatePageLinks(); // In case there are data-navigo links on the loaded page
        }).catch(function (err) {
            console.warn('Something went wrong.', err);
        });
    });

    // set the 404 route
    router.notFound((query) => {
        routerContainer.innerHTML = '<div class="container text-center py-5 my-5"><h1>Page introuvable !</h1><br><h2>Erreur 404</h2><hr><i class="fa fa-10x fa-exclamation-triangle"></i></div>';
        setPageAfterload('404');
        console.warn('Route not found');
        console.warn(query);
    });

    router.resolve();
    /*
    fetch('http://example.org').then(function(response) {
        if (response.ok) {
            return response.json()
        } else {
            throw ("Error " + response.status);
        }
    }).then(function(data) {
        // do what you have to do with data
    }).catch(function(err) {
        console.log(err);
    });
    */
}